import { Link } from "react-router-dom";

export default function Footer() {
    return(
        <footer>
            <p>&copy; Pooch 2023. All Rights Reserved.</p>
            <div className="links">
                <Link to='/privacy-policy'>Privacy Policy</Link>
                <Link to='/terms-and-conditions'>Terms and Conditions</Link>
            </div>
        </footer>
    );
}