import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogTile from "../components/blog_tile";
import Footer from "../components/footer";
import TopBar from "../components/top_bar";
import Api from "../config/api_config";
import { Blog } from "../interfaces/interfaces";

export default function BlogScreen() {

    const [blogs, setBlogs] = useState<Blog[]>([]);

    useEffect(() => {
        Api.get("/blog")
        .then(function(response) {
            const blogObjects = response.data.data;
            const converted: Array<Blog> = blogObjects.map((e: object) => {
                return e as Blog;
            });
            console.log(converted);
            setBlogs(converted);
        })
        .catch(function(err) {
            
        });
    }, []);

    return(
        <div>
            <TopBar active="blog" />

            {
                (blogs.length > 0) ? <div className="blogContainer offset">
                {
                    blogs.map((blog) => {
                        return <BlogTile key={blog.id} blog={ blog } />
                    })
                }
                </div> : <div className="fullScreen center">
                    <h1>Nothing here..</h1>
                    <p>Please check back later.</p>
                    <Link to='/'>Back to Home</Link>
                </div>
            }

            

            <div className="fillSpace"></div>

            <Footer />
        </div>
    );
}