import Footer from "../components/footer";
import TopBar from "../components/top_bar"

export default function PrivacyPolicyScreen() {
    return (
        <div>
            <TopBar active="privacy-policy" />

            <div className="container document">

                <h2>PRIVACY POLICY

                </h2>
                <h3>Welcome to Pooch!</h3>
                <p>Pooch values your privacy. This notice describes what information we collect, how we use it, and, if necessary, those with whom we share it. <br />
                    By visiting Pooch, you are accepting the practices described in this Privacy Policy. Your use of the application is also subject to our Terms and Conditions.
                    <br />
                    This Privacy Policy may change from time to time. Your continued use of the application after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. This Privacy Policy has been developed and is maintained in accordance with all applicable federal and international laws and regulations and specifically with the California Online Privacy Protection Act (CalOPPA – U.S regulation), the GDPR (General Data Protection Regulation - European regulation).
                </p>

                <h3>GENERAL INFORMATION</h3>
                <p>The personal data of the users that are collected and processed through:<br />
                    <b>Pooch (Android and iOS version – Available on Google Play and Apple store)</b>
                    <br />Will be under responsibility and in charge of:

                    <li>Pooch</li>
                    <li>Grant Horton</li>

                    (Hereinafter referred to as Pooch).</p>

                <h3>TYPES OF INFORMATION GATHERED</h3>
                <p>The information we learn from customers helps personalize and continually improve your experience at Pooch. Here are the types of information we gather:<br />
                    <b>Information You Give Us.</b> You provide information when you download the application, register as a user, buy a subscription and/or communicate with us. As a result of those actions, you might supply us with the following information:
                    <li>First and last name</li>
                    <li>Profile Photos</li>
                    <li>Age and date of birth</li>
                    <li>Email address</li>
                    <li>Phone number (includes WhatsApp)</li>
                    <li>Pet information</li>
                    <li>Hobbies and interests</li>
                    <li>Facebook profile information</li>
                    <li>GPS information (location)</li>
                    <li>IP address</li>
                    <li>Any additional information relating to you that you provide to us directly through our application.</li></p>
                <p><b>Location information (GPS):</b> by downloading and using the application you authorize Pooch to use the GPS system integrated in the user's phone for the sole and exclusive purpose of providing the appropriate searches of user profiles, according to the user's location. The location information and the GPS system will be used only for the proper functioning of the application and its functionality.<br /></p>
                <p><b>Payment information: </b>Your credit card or payment information will be processed by the payment platforms available in the application. which will treat and safeguard your data with total security and with the exclusive purpose of processing the purchase of the subscriptions. Mélange Drinks reserves the right to contract any payment platform available in the market, which treats your data for the exclusive purpose of processing the purchase of the subscriptions.</p>

                <h3>HOW LONG WE KEEP YOUR DATA</h3>
                <p>Personal data will be kept for the time necessary for the provision of the application or as long as the user does not withdraw his consent. The user's personal data will be deleted from our database immediately after the user withdraws his consent or his account is deleted.</p>

                <h3>HOW WE USE YOUR INFORMATION.</h3>
                <p>In general, we use the information we collect primarily to provide, maintain, protect and improve our application. We use personal information collected through our site as described below and described elsewhere in this Policy to:
                    Identify you as a user in our system.
                    <li>Users registration.</li>
                    <li>Provide the application (Android and iOS version).</li>
                    <li>Provide the application's functionalities.</li>
                    <li>Provide the subscriptions.</li>
                    <li>Process payments.</li>
                    <li>Improve our application and how we operate our business.</li>
                    <li>Understand and enhance your experience using our application.</li>
                    <li>Respond to your comments or questions through our support team.</li>
                    <li>Send you related information, including confirmations, technical notices, updates, security alerts and support and administrative messages.</li>
                    <li>Communicate with you about upcoming events, offers and news about products and services offered by Pooch’s application.</li>
                    <li>Marketing purposes of Pooch.</li>
                    <li>Protect, investigate and deter against fraudulent, unauthorized or illegal activity.</li></p>

                <h3>HOW DO YOU GET MY CONSENT?</h3>
                <p>By downloading the application, registering and provide us with personal information to communicate with you, you agree that we collect and use your information. You can withdraw your consent, contact us through the contact information or the contact page.</p>

                <h3>HOW WE SHARE INFORMATION</h3>
                <p>Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below.<br />
                    <b>Other users:</b> information provided through user profiles may be shared and viewed by other users registered in the application. By providing information through your profile you authorize Pooch to display and share your information with other registered users in the manner described in this privacy policy.<br />
                    <b>Third-Party Service Providers. </b>We employ other companies and individuals to perform functions on our behalf. Examples include process payments, sending emails, analyzing data, providing marketing assistance, providing search results and links. They have access to personal information needed to perform their functions, but may not use it for other purposes.<br />
                    <b>Business Transfers.</b> In the event that Pooch creates, merges with, or is acquired by another entity, your information will most likely be transferred. Pooch will email you or place a prominent notice on our applicaton before your information becomes subject to another privacy policy.<br />
                    <b>Protection of Pooch and others.</b> We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of Pooch, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.<br />
                    <b>With Your Consent.</b> Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.<br />
                    <b>Anonymous Information.</b> Pooch uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the application. We may also use aggregated anonymous information to provide information about the application to potential business partners and other unaffiliated entities. This information is not personally identifiable.<br />
                    <b>Email Address.</b> The email address that you supply to us for purposes of receiving our email communications will never be rented or sold to a third party.</p>

                <h3>PROTECTING YOUR INFORMATION</h3>
                <p>We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input. If transactions are processed on the application, the transaction information is transmitted to and from the application in encrypted form using industry standard SSL connections to help protect that information from interception. We restrict authorized access to your personal information to those persons who have a legitimate purpose to know that information to provide products or services to you and those persons you have authorized to have access to such information.<br />
                    Pooch follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once Pooch receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Pooch strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.<br />
                    We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so.</p>

                <h3>YOUR RIGHTS</h3>
                <p>You have the following rights in relation to your personal data provided to Pooch:<br />
                    <li>Access your personal data.</li>
                    <li>Request the correction of inaccurate data.</li>
                    <li>Request limitation of the processing of your data.</li>
                    <li>Oppose the treatment of your data.</li>
                    <li>Request your portability.</li>
                    <li>Not be subject to automated individual decisions.</li></p>
                <p>The Users can exercise all these rights contacting us through the contact information or the contact page and indicating the reason for his request.<br />
                    Without preconception to any other administrative appeal or legal action, the user shall have the right to submit a claim to a control authority, in particular in the State in which he or she has their habitual residence, place of work or place of the alleged infringement, If you consider that the processing of your personal data is not appropriate to the regulations, as well as in the case of not seeing the exercise of your rights satisfied. The control authority in which the claim was filed will inform the claimant about the course and result of the claim.</p>

                <h3>EU MEMBERS’ RIGHTS (GDPR COMPLIANT)</h3>
                <p>If you are habitually located in the European Union, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information. While some of these rights apply generally, certain rights apply only in certain limited circumstances.<br />
                    We describe these rights below:<br />
                    <li>Access and Porting: You can access your information at any time.</li>
                    <li>Rectify, Restrict, Limit, Delete: You can also rectify, restrict, limit or delete much of your information by contact us.</li>
                    <li>Object: Where we process your information based on our legitimate interests explained above, or in the public interest, you can object to this processing in certain circumstances. In such cases, we will cease processing your information unless we have compelling legitimate grounds to continue processing or where it is needed for legal reasons.</li>
                    <li>Revoke consent: Where you have previously provided your consent, such as to permit us to process data about you, you have the right to withdraw your consent to the processing of your information at any time. For example, you can withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn consent if we have a legal basis to do so or if your withdrawal of consent was limited to certain processing activities.</li>
                    <li>Complain: Should you wish to raise a concern about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority.</li></p>
                <p>Additionally, if you are a European resident, we note that we are processing your information in order to fulfil contracts we might have with you or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe.
                    You can see more about these rights at:<br />
                    https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en</p>

                <h3>CHILDREN’S ONLINE PRIVACY PROTECTION</h3>
                <p>We are in compliance with the requirements of the California Online Privacy Protection Act (CalOPPA – U.S regulation) and the GDPR (General Data Protection Regulation - European regulation) regarding the protection of the personal data of minors. We do not collect any information from anyone under 13 years of age. Our application and services are all directed to people who are at least 18 years old or older.</p>

                <h3>EDITING AND DELETING INFORMATION</h3>
                <p>If you believe that any information, we are holding on you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect. You can change, modify, rectify and delete your Information at any time, please contact us through the contact information.<br />
                    To opt-out of Pooch email, follow the instructions included in the email. Your request should be processed within 48 hours.</p>

                <h3>SUPPLIERS AND OTHER THIRD PARTIES</h3>
                <p>Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information Pooch collects from you. If you disclose your information to others, whether other users or suppliers on Pooch, different rules may apply to their use or disclosure of the information you disclose to them. Pooch does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Pooch is not responsible for the privacy or security practices of other websites on the Internet, even those linked to or from the Pooch site. Pooch encourages you to ask questions before you disclose your personal information to others.</p>

                <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                <p>Pooch will post modifications by date to this Privacy Policy to inform you of any changes. Pooch reserves the right to modify this Privacy Policy at any time, please review it frequently.</p>

                <h3>CONTACT INFORMATION</h3>
                <p>If you have questions or concerns about these Privacy Policy and the handling and security of your data, please contact us at:</p>

            </div>

            <Footer />
        </div>
    );
}