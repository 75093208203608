import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BlogScreen from './screens/blog_screen';
import BlogViewScreen from './screens/blog_view_screen';
import HomeScreen from './screens/home_screen';
import NotFoundScreen from './screens/not_found_screen';
import PrivacyPolicyScreen from './screens/privacy_policy_screen';
import TermsAndConditionsScreen from './screens/terms_and_conditions_screen';
import './stylesheets/default.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    
    <BrowserRouter>
      <Routes>
        {/* Client Routes */}
        <Route path='*' element={ <NotFoundScreen /> } />
        <Route path='/' element={ <HomeScreen /> } />
        <Route path='/blog' element={ <BlogScreen /> } />
        <Route path='/blog/:id' element={ <BlogViewScreen /> } />
        <Route path='/privacy-policy' element={ <PrivacyPolicyScreen /> } />
        <Route path='/terms-and-conditions' element={ <TermsAndConditionsScreen /> } />
      </Routes>
    </BrowserRouter>

  </React.StrictMode>
);