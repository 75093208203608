import axios from "axios";

const API_SECRET_KEY = "siuiu897s9db222xix002isish2b2b2susupoochdatingllc29";

const adminAuthToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJHZU9BMkFyQ1lFUTBKODFPN0ZJaFdzWk5HaW8yIiwiaWF0IjoxNjY3NDk3NDc3fQ.CkNMG4YALQ4GGVjFnx8DH8KhmwjZNGhAx6kK2pVJmfE";

const adminUserId = "GeOA2ArCYEQ0J81O7FIhWsZNGio2";

const BASE_URL = "http://localhost:5000/api";

const DEFAULT_HEADERS = {
    "auth-token": adminAuthToken,
    "userid": adminUserId,
    "secretkey": API_SECRET_KEY
};

const Api = axios.create({
    baseURL: BASE_URL,
    headers: DEFAULT_HEADERS,
    timeout: 5000
});

export default Api;