import { Link } from "react-router-dom";
import TopBar from "../components/top_bar";

export default function NotFoundScreen() {
    return(
        <div>
            <TopBar active="not-found" />

            <div className="fullScreen center">
                <h1>oops!</h1>
                <p>The page you're looking for was not found.</p>
                <Link to='/'>Back to Home</Link>
            </div>
        </div>
    );
}