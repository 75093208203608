import { Link } from "react-router-dom";
import Footer from "../components/footer";
import ReviewSlider from "../components/review_slider";
import TopBar from "../components/top_bar";

export default function HomeScreen() {
    return(
        <div>
            <TopBar active="home" />
            
            <div className="flexContainer">
                <div className="text">
                    <h1>THE ONLY DATING APP FOR YOU AND YOUR PET</h1>
                    <div className="row">
                        <a target='_blank' href='https://apps.apple.com/in/app/pooch-dating/id1622752577'><img src="assets/download-apple.png" alt="" className="downloadButton" /></a>
                        {/* <img src="assets/download-google.png" alt="" className="downloadButton" /> */}
                    </div>
                </div>
                <div className="image">
                    <img src="assets/img1.jpg" alt="Picture" />
                </div>
            </div>

            <div className="flexContainer">
                <div className="image">
                    <img src="https://images.pexels.com/photos/4921291/pexels-photo-4921291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Picture" />
                </div>
                <div className="text">
                    <h2>We are not just your regular dating app</h2>
                    <p>Pooch Dating is an ever growing community of Pet lovers. No matter if your friend is furry, scaly or feathery, letting you connect with people of similar interest. We offer something for everyone, with amazing features we make it easy to match, chat and swap cute pet photos.</p>
                </div>
            </div>

            <div className="flexContainer">
                <div className="text">
                    <h2>Why Pooch?</h2>
                    <p>Our goal at Pooch is to build a fun and positive way to connect with new people and create new memories. We strive to be the first and the best dating app with a focus on your pets. Pets are a huge aspect of your life, they deserve to be part of your dating experience. Meet and chat with Local pet lovers, build a connection and take the conversation outside, dog parks are a perfect way to meet new friends and their companions!</p>
                </div>
                <div className="image">
                    <img src="https://images.unsplash.com/photo-1527611560031-bcf6da68640c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1851&q=80" alt="Picture" />
                </div>
            </div>

            <div className="container">
                <h2>What people say about us?</h2>
                <ReviewSlider />
            </div>

            <div className="container">
                <h2>Follow us @Poochdating</h2>
            </div>

            <Footer />
            
        </div>
    );
}