import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopBar from "../components/top_bar";
import Api from "../config/api_config";
import { Blog } from "../interfaces/interfaces";
import { returnFormattedDate } from "../services/date_services";

export default function BlogViewScreen() {

    const [isLoading, setLoading] = useState(true);
    const [blog, setBlog] = useState<Blog>();
    const params = useParams();

    useEffect(() => {
        Api.get(`/blog?id=${params.id}`)
        .then(function(response) {
            const blogObjects = response.data.data as Array<object>;
            const converted: Array<Blog> = blogObjects.map((e) => {
                return e as Blog;
            });

            if(converted.length > 0) {
                setBlog(converted[0]);
                setLoading(false);
                console.log(blogObjects[0]);
            }
        })
        .catch(function(err) {
            alert(err.toString());
        });
    }, []);

    return(
        <div>
            <TopBar active="blog" />

            <div className="blogContainer offset">
                {
                    (isLoading === false) ? <div>
                        <h1>{ blog?.title }</h1>
                        <h2>{ blog?.description }</h2>
                        <p className="details">{ returnFormattedDate(blog?.createdOn ?? new Date()) } - { blog?.readTime.toString() } mins read</p>
                        <img src={ blog?.thumbnail } alt="" />
                        <div dangerouslySetInnerHTML={ {__html: blog?.content ?? ""} }></div>
                    </div> : <div></div>
                }
            </div>
        </div>
    );
}