import { Link } from "react-router-dom";

interface TopBarArguments {
    active: string
}

export default function TopBar({ active }: TopBarArguments) {
    return(
        <div className="topBar">
            <Link className="logo" to='/'>POOCH</Link>
            <div className="menu">
                <Link className={ (active == "home") ? 'active' : '' } to='/'>Home</Link>
                <Link className={ (active == "blog") ? 'active' : '' } to='/blog'>Blog</Link>
            </div>
        </div>
    );
}