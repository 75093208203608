export default function ReviewSlider() {
    return(
        <div className="reviewSlider">
            <div className="wrapper">

                <div className="review">
                    <p>Amazing app! This app is super cool. My dog will find all the girlfriends and maybe his true love 🤷‍♂️</p>
                    <h3>Alyssa, USA</h3>
                </div>
    
                <div className="review">
                    <p>I love animals and am happy to be in a community where others share that same interest.</p>
                    <h3>Scanna, USA</h3>
                </div>
    
                <div className="review">
                    <p>My dog has been lonely all his life but this app has changed everything 🥰🥰🥰</p>
                    <h3>Ryan, USA</h3>
                </div>
    
                <div className="review">
                    <p>This app is crazy good. Finding other people with pets has never been so easy.</p>
                    <h3>Rohit Semriwal, India</h3>
                </div>

            </div>
        </div>
    );
}