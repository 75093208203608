import { Link } from "react-router-dom";
import { Blog } from "../interfaces/interfaces";
import { returnFormattedDate } from "../services/date_services";

interface BlogTileArguments {
    blog: Blog
}

export default function BlogTile({ blog }: BlogTileArguments) {
    return(
        <Link to={ `/blog/${blog.id}` }>
            <div className="blogTile">
                <div className="text">
                    <h3>{ blog.title }</h3>
                    <p>{ blog.description }</p>
                    <br />
                    <p>{ returnFormattedDate(blog.createdOn) } - { blog.readTime.toString() } mins read</p>
                </div>
                <img src={ blog.thumbnail } alt="" />
            </div>
        </Link>
    );
}