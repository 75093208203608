const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dev",
];

export function returnFormattedDate(date: Date) {
    const datePart = date.toString().split("T")[0];
    const timePart = date.toString().split("T")[1];

    const day = parseInt(datePart.split("-")[2]);
    const month = parseInt(datePart.split("-")[1]) - 1;
    const year = parseInt(datePart.split("-")[0]);

    return `${months[month]} ${day}, ${year}`;
}